.accordion_card .accordion-button:focus{
    border: 0;
    box-shadow: unset;
}
.accordion_card .accordion-body{
    background-color: #ff000017;
}
.offcanvas-custom{
    width: 40% !important;
}
@media(max-width:768px){
    .offcanvas-custom{
        width: 60% !important;
    }
}
@media(max-width:475px){
    .offcanvas-custom{
        width: 100% !important;
    }
}